<template>
	<button @click="createOrder()" class="btn btn-primary me-2">Create Order</button>
	<button @click="deposit()" class="btn btn-primary">Deposit</button>
</template>

<script setup>
import Tensor from '../services/tensor'

const tensor = new Tensor

async function createOrder() {
	await tensor.createOrder()
}

async function deposit() {
	await tensor.deposit()
}

</script>